<template>
  <v-row>
    <v-col cols="12" class="py-0 px-0">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="data"
        :expanded.sync="expanded"
        hide-default-footer
        item-key="id"
        :items-per-page="sizes[size]"
        :show-expand="expandedHeaders.length > 0"
        show-select
        :loading="loading"
        loading-text="Loading data from API...please wait"
        class="synapsa-table"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-by="customSort"
        @update:sort-desc="customSort"
      >
        <template v-slot:top>
          <v-toolbar flat v-if="selected.length > 0">
            <v-spacer></v-spacer>
            <v-icon v-if="processing" color="primary" class="va-middle mr-2"
              >fas fa-spinner fa-spin</v-icon
            >
            <revert-menu
              :selected="selected"
              :processing="processing"
              @proceed="proceed()"
            />
          </v-toolbar>
        </template>
        <template v-slot:item.firewalls.name="{ item }">
          <v-chip
            v-if="item.firewall"
            x-small
            color="neutral-4"
            class="mr-1 text-white"
          >
            {{ item.firewall.name }}
          </v-chip>
          <span v-else>-</span>
        </template>
        <template v-slot:item.secPolicy="{ item }">
          {{ item.sec_policy }}
        </template>
        <template v-slot:item.severity="{ item }">
          <v-icon :class="severityClass(item.severity)"> mdi-view-grid </v-icon>
          {{ severityNames[item.severity - 1] }}
        </template>
        <template v-slot:item.message="{ item }">
          {{ item.message }}
        </template>
        <template v-slot:item.auditors.name="{ item }">
          {{ item.auditor }}
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at }}
        </template>
        <template v-slot:item.confirm_comment="{ item }">
          <div>
            <p>
              {{ item.confirmComment }}
            </p>
            <p>
              <span v-html="fullMessageHtml(item.fullmessage)" />
            </p>
          </div>
        </template>
        <template
          v-if="expandedHeaders.length"
          v-slot:expanded-item="{ headers, item }"
        >
          <td
            :colspan="headers.length"
            class="expand-block"
            :class="data.indexOf(item) === data.length - 1 ? 'last' : ''"
          >
            <v-simple-table>
              <template v-slot:default>
                <tbody class="expanded">
                  <tr
                    v-for="(expandItem, key) in expandedHeaders"
                    :key="expandItem.value"
                    class="expand-tr"
                    :class="
                      data.indexOf(item) === data.length - 1 &&
                      key === expandedHeaders.length - 1
                        ? 'last'
                        : ''
                    "
                  >
                    <td
                      v-if="expandItem.value === 'firewalls.name'"
                      width="100"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'firewalls.name'">
                      {{ item.firewall.name }}
                    </td>
                    <td
                      v-if="expandItem.value === 'auditors.name'"
                      width="100"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'auditors.name'">
                      {{ item.auditor }}
                    </td>
                    <td
                      v-if="expandItem.value === 'secPolicy'"
                      width="100"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'secPolicy'">
                      {{ item.sec_policy }}
                    </td>
                    <td
                      v-if="expandItem.value === 'severity'"
                      width="100"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'severity'">
                      <v-icon :class="severityClass(item.severity)">
                        mdi-view-grid
                      </v-icon>
                      {{ severityNames[item.severity - 1] }}
                    </td>
                    <td
                      v-if="expandItem.value === 'message'"
                      width="100"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'message'">
                      {{ item.message }}
                    </td>
                    <td
                      v-if="expandItem.value === 'created_at'"
                      width="100"
                      class="pl-3"
                    >
                      {{ expandItem.text }}
                    </td>
                    <td v-if="expandItem.value === 'created_at'">
                      {{ item.created_at }}
                    </td>
                    <td
                      v-if="expandItem.value === 'confirm_comment'"
                      width="100"
                      class="pl-3"
                    >
                      Comment:
                    </td>
                    <td v-if="expandItem.value === 'confirm_comment'">
                      <p>
                        {{ item.confirmComment }}
                      </p>
                      <p>
                        <span v-html="fullMessageHtml(item.fullmessage)" />
                      </p>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </td>
        </template>
      </v-data-table>
    </v-col>
    <v-col v-if="data.length > 0" cols="6" class="py-0 px-0 synapsa-pagination">
      <v-btn-toggle v-model="size" tile group class="results px-1">
        <v-btn x-small class="result" rounded> 5 </v-btn>

        <v-btn x-small class="result" rounded> 10 </v-btn>

        <v-btn x-small class="result" rounded> 25 </v-btn>

        <v-btn x-small class="result" rounded> 50 </v-btn>

        <v-btn x-small class="result" rounded> 100 </v-btn>

        <v-btn x-small class="result" rounded> 200 </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col
      v-if="data.length > 0"
      cols="6"
      class="text-right py-0 px-0 synapsa-pagination"
      :class="$vuetify.theme.dark ? 'theme--dark' : 'theme--light'"
    >
      <v-pagination
        v-model="page"
        :length="pageTotalCount"
        :total-visible="7"
        circle
        class="d-inline-block pagination px-1 py-1"
        :class="page > 99 ? 'over100' : 'less100'"
      />
    </v-col>
  </v-row>
</template>

<script>
import RevertMenu from "./revert";
export default {
  components: {
    RevertMenu,
  },
  props: {
    headers: {
      type: Array,
      default: function () {
        return [];
      },
    },
    expandedHeaders: {
      type: Array,
      default: function () {
        return [];
      },
    },
    data: {
      type: Array,
      default: function () {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tablePage: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tablePageTotalCount: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    tableSize: {
      type: Number,
      default: function () {
        return 1;
      },
    },
    processing: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      selected: [],
      expanded: [],
      location: "",

      page: 1,
      pageTotalCount: 1,
      size: 1,
      sortBy: undefined,
      sortDesc: undefined,

      sizes: [5, 10, 25, 50, 100, 200],
      severityColors: ["neutral-3", "good-3", "medium-3", "bad-3"],
      severityNames: ["Low", "Medium", "High", "Critical"],
    };
  },
  computed: {
    keywordAddForm: {
      get() {
        return this.$store.state.settings.keywordAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "keywordAddForm",
          value: val,
        });
      },
    },
    idAddForm: {
      get() {
        return this.$store.state.settings.idAddForm;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "idAddForm",
          value: val,
        });
      },
    },
  },
  watch: {
    tablePage: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.page = newValue;
      },
    },
    tablePageTotalCount: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pageTotalCount = newValue;
      },
    },
    tableSize: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) this.size = this.sizes.indexOf(newValue);
      },
    },
    page: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue && newValue !== this.tablePage) {
          this.$emit("change", {
            page: newValue,
            size: this.sizes[this.size],
            sortDesc: this.sortDesc,
            by: this.sortBy,
          });
        }
      },
    },
    size: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue !== this.sizes.indexOf(this.tableSize)) {
          this.$emit("change", {
            page: this.page,
            size: this.sizes[newValue],
            sortDesc: this.sortDesc,
            by: this.sortBy,
          });
        }
      },
    },
    processing: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.selected = [];
        }
      },
    },
  },
  methods: {
    fullMessageHtml(message) {
      let html = message.replaceAll("[", "<span class='primary--text'>[");
      html = html.replaceAll("]", "]</span>");
      return html;
    },
    proceed() {
      this.$emit("proceed", { selected: this.selected });
    },
    customSort() {
      // Tato divocin vznikla z dovodu ze vuetify ma na tabulke 3 stavy a ked nastane 3 stav nesortuj podla nicoho tak
      // sa zavolal 2x change a niekedy to hodilo zly sort aj napriek spravnemu response z DB
      if (
        (this.sortBy !== undefined && this.sortDesc !== undefined) ||
        (this.sortBy === undefined && this.sortDesc === undefined)
      ) {
        this.$emit("change", {
          page: this.page,
          size: this.sizes[this.size],
          sortDesc: this.sortDesc,
          by: this.sortBy,
        });
      }
    },
    severityClass(status) {
      if (status === 1 || status === "1") {
        return "signal-icon--low";
      } else if (status === 2 || status === "2") {
        return "signal-icon--medium";
      } else if (status === 3 || status === "3") {
        return "signal-icon--high";
      } else {
        return "signal-icon--critical";
      }
    },
  },
};
</script>
